import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';

// login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const DashboardDefault = Loadable(lazy(() => import('views/diiot/panel')));
const RealtimeDefault = Loadable(lazy(() => import('views/diiot/realtime')));
const ElectrParamDefault = Loadable(lazy(() => import('views/diiot/electr-params')));
const AnalisisHistoricoDefault = Loadable(lazy(() => import('views/diiot/analisisconsumo')));
const EventosDefault = Loadable(lazy(() => import('views/diiot/eventos')));
const ConfCustomEvent = Loadable(lazy(() => import('views/diiot/abm/customEvent/confCustomEvent')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/monitor/:deviceCode',
      element: <DashboardDefault />
    },
    {
      path: '/pages/',
      element: <MainLayout />,
      children: [
        {
          path: 'realtime/:deviceCode',
          element: <RealtimeDefault />
        }
      ]
    },
    {
      path: '/pages/',
      element: <MainLayout />,
      children: [
        {
          path: 'electr-param/:deviceCode',
          element: <ElectrParamDefault />
        }
      ]
    },
    {
      path: '/pages/',
      element: <MainLayout />,
      children: [
        {
          path: 'analisishistorico/:deviceCode',
          element: <AnalisisHistoricoDefault />
        }
      ]
    },
    {
      path: '/pages/',
      element: <MainLayout />,
      children: [
        {
          path: 'events/:deviceCode',
          element: <EventosDefault />
        }
      ]
    },
    {
      path: '/pages/',
      element: <MainLayout />,
      children: [
        {
          path: 'custom-events/:deviceCode',
          element: <ConfCustomEvent />
        }
      ]
    }
  ]
};

export default AuthenticationRoutes;
